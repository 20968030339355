<template>
  <div v-if="displayed" :class="!searching ? 'dropdown-selection-wrapper' : 'dropdown-selection-wrapper-searching'">
    <div
      v-if="!searching && showTitle"
      class="dropdown-submenu dropdown-option"
      :class="currentlyOpen.includes(name) ? 'open-submenu' : 'close-submenu'"
      @click="optionOpened(name)"
    > 
      {{ name }}
      <div v-if="hasSortButton" class="sort-button" :class="{ 'sort-button-reversed': !isReversed }">
        <img class="sort-button-icon"
          src="../../../assets/icons/icn_arrowdown.svg" 
          alt="sort icon"
        >
      </div>
    </div>
    <div v-for="option in sortedOptions"
         :key="option.id"
    >
      <div v-if="filtered(option)"
           class="dropdown-option option-item"

           :class="['multi-option', isSelected ? 'selected' : '']"
           @click="optionSelected(option)">
        <img v-if="isSelected(option)"
             src="../../../assets/icons/icn_select.svg"
             alt="checkbox icoon"
             class="checkbox-icon">
        <img v-if="!isSelected(option)"
             src="../../../assets/icons/icn_unselect.svg"
             alt="checkbox icoon"
             class="checkbox-icon">
        <div class="option-text"
             :class="option.customClass"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { computed, ref } from 'vue'

export default {
  name: 'DropdownSection',
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: undefined,
      note: 'can either be dropdown Items, or setup for another dropdown'
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    displayed: {
      type: Boolean,
      default: true
    },
    searchFilter: {
      type: String,
      required: false,
      default: ''
    },
    appliedFilters: {
      type: Array,
      required: false,
      default: undefined
    },
    hasSortButton: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['options', 'options-remove'],
  setup(props, context) {
    //any selected options at this level
    //any lower menus are open
    const currentlyOpen = ref([])

    const isReversed = ref(false)

    function isSelected(option) {
      return props.appliedFilters.some(o => option.id === o.id && option.type === o.type)
    }

    const sortedOptions = ref(props.options);

    function reverseOptions() {
      sortedOptions.value.reverse()
      isReversed.value = !isReversed.value
    }

    const searching = computed(() => props.searchFilter !== '')
    function filtered(option) {
      if (props.searchFilter === '') {
        return true
      } else {
        const lower = props.searchFilter.toLowerCase()
        return option.name.toLowerCase().includes(lower)
      }
    }

    function optionOpened(choice) {
      if(props.hasSortButton){
        reverseOptions()
      }

      const index = currentlyOpen.value.indexOf(choice)
      if (index === -1) {
        currentlyOpen.value.push(choice)
      } else {
        currentlyOpen.value.splice(index, 1)
      }
    }


    function optionSelected(choice) {
      choice.type = props.type
      choice.singleSelection = props.singleSelection
      context.emit('options', choice)
    }

    return {
      currentlyOpen,

      isSelected,
      optionSelected,
      optionOpened,
      filtered,
      searching,
      isReversed,
      sortedOptions,
      reverseOptions
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.dropdown-selection-wrapper{
  //padding-left: rem(10);
}

.dropdown-selection-wrapper-searching{
  padding-left: rem(0);
}

.dropdown-option{
  padding: rem(15) rem(10) rem(15) rem(17);
  display: flex;
  justify-content: flex-start;
  align-content: center;
  gap: rem(20);

  &:hover {
      background-color: #F4F6FB;
      cursor: pointer;
  }
  border-top: 1px solid #F4F6FB;

}

.dropdown-option-item{

}

.dropdown-submenu{
  font-weight: 1000;
  gap: rem(8);
}

.single-option{

}

.checkbox-icon{
  width: rem(15);
}

.multi-option{

}


.selected{

}

// todo: move this to parent and use .deep()
.pending, .approved, .invitedByHelpdesk, .invitedByTeacher, .emailNotVerified, .oldMMAccount, .inRegistration {
  padding: 0.4rem;
  border-radius: rem(5);
}

.pending {
  color: rgb(248,170,105);
  background-color: #FDF2E8;
}

.approved {
  color: #37c6be;
  background-color: #e5f7f6;
}

.invitedByTeacher, .invitedByHelpdesk, .inRegistration {
  color: #ccb528;
  background-color: #f7f7e5;
}
.emailNotVerified {
  color: #aa0019;
  background-color: #f7d3ce;
}
.oldMMAccount {
  background-color: #f6f7fa;
  color: #8d877c;
}

.sort-button {
  display: flex;
  align-items: center;
  transition: transform 0.3s;

  .sort-button-icon {
    width: rem(10);
    height: rem(10);
    opacity: 0.8;
  }
}

.sort-button-reversed {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

</style>
